var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "countries3377",
          tooltipMsg: "manualofcountri",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "CountryStatus", ascending: 1 },
          modelId: "CountryId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "CountryDomainName",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://" + list.row.CountryDomainName,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.CountryDomainName))]
                  ),
                ]
              },
            },
            {
              key: "CountryStatus",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm.countryStatusTexts[list.row.CountryStatus])
                  ),
                ]
              },
            },
            {
              key: "CurrencyId",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm.currencyIdTexts[list.row.CurrencyId])),
                ]
              },
            },
          ],
          null,
          false,
          2101236812
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }