import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'CountryId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CountryNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalSk',
		i18n: 'localsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalCz',
		i18n: 'localcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryDomainName',
		i18n: 'domain3379',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryStatus',
		i18n: 'status',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'LanguageCode',
		i18n: 'language',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurrencyId',
		i18n: 'currency',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
];
