<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="countries3377"
		tooltipMsg="manualofcountri"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'CountryStatus', ascending: 1 }"
		modelId="CountryId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #CountryDomainName="list">
			<a :href="'https://' + list.row.CountryDomainName" target="_blank">{{ list.row.CountryDomainName }}</a>
		</template>

		<template #CountryStatus="list">{{ countryStatusTexts[list.row.CountryStatus] }}</template>

		<template #CurrencyId="list">{{ currencyIdTexts[list.row.CurrencyId] }}</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions, getColumnDropdownOptionsAsDict } from '@/components/general/utils';
import * as links from '@/router/links';
import serviceCommonApi from '@/services/api/common.api';
import serviceEnums from '@/services/service/enums.service';

import { tableColumns } from './country.table-data';

export default {
	name: 'CountryTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'CountryListItem',
		};
	},

	computed: {
		countryStatusTexts() {
			return getColumnDropdownOptionsAsDict(this.columns, 'CountryStatus');
		},

		currencyIdTexts() {
			return getColumnDropdownOptionsAsDict(this.columns, 'CurrencyId');
		},
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(CountryId = 0) {
			this.$router.push({
				name: links.countryDetailName,
				params: { CountryId },
			});
		},

		async prepareColumns(columns) {
			const curencies = await serviceCommonApi.getStandardList({}, 'Currency');

			let cols = prepareCountryStatus.bind(this)(columns);
			cols = prepareCurrencyId(cols);

			return columns;

			function prepareCountryStatus(columns) {
				const values = serviceEnums.getEnumForDropdown('countryStatus', true).map((cs) => {
					cs.text = this.$t(cs.text.toLowerCase());
					return cs;
				});

				return setDropdownOptions(columns, 'CountryStatus', values);
			}

			function prepareCurrencyId(columns) {
				const values = curencies.data.map((cur) => ({
					text: cur.CurrencyCode,
					value: cur.CurrencyId,
				}));

				values.unshift({
					text: '',
					value: null,
				});

				return setDropdownOptions(columns, 'CurrencyId', values);
			}
		},
	},
};
</script>